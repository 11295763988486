/**
 * Cookie Banner
 */

/*
 Connections:
 -------------------------------------------------------------------------------
 * google - GTM code
 * gads - Google Ads Remarketing
 * hotjar - HotJar
 * fb - Facebook pixel
 * maps - Google Maps
 * players - every player on website: Youtube, Slideshare
 * tiktok - TikTok pixel
 * linkedin - Linkedin pixel
 * smarts - Smartsupp
*/

class SS_CookieBanner {
    constructor() {

        this.config = {
        debugMode: true,
        userConsents: {
          google: false,
          hotjar: false,
          fb: false,
          maps: false,
          players: false,
          gads: false,
          tiktok: false,
          linkedin: false ,
          smarts: false
        },
        groups: [
          'google',
          'hotjar',
          'fb',
          'maps',
          'players',
          'gads',
          'tiktok',
          'linkedin',
          'smarts'
        ],
        allowedGroups: []
      };

      this.UI = {
        basicWindow: $('.ss-cn-info'),
        extendedWindow: $('.ss-cn-info-extended'),
        buttons: {
          basicShowExtended: $('.ss-cn-info-button-show-extended'),
          basicAcceptAll: $('.ss-cn-info-button-accept-all'),
          basicRejectAll: $('.ss-cn-info-button-reject-all'),
          extendedAcceptAll: $('.ss-cn-info-extended-button-accept-all'),
          extendedSaveConfig: $('.ss-cn-info-extended-button-save-consent'),
          extendedNotNow: $('.ss-cn-info-extended-button-close-without-save')
        },
        checkboxes: {
          google: $('.ss-cn-consent-checkbox[data-consent-type="google"]'),
          hotjar: $('.ss-cn-consent-checkbox[data-consent-type="hotjar"]'),
          fb: $('.ss-cn-consent-checkbox[data-consent-type="fb"]'),
          maps: $('.ss-cn-consent-checkbox[data-consent-type="maps"]'),
          players: $('.ss-cn-consent-checkbox[data-consent-type="players"]'),
          gads: $('.ss-cn-consent-checkbox[data-consent-type="gads"]'),
          tiktok: $('.ss-cn-consent-checkbox[data-consent-type="tiktok"]'),
          linkedin: $('.ss-cn-consent-checkbox[data-consent-type="linkedin"]'),
          smarts: $('.ss-cn-consent-checkbox[data-consent-type="smarts"]'),
        }
      };

      if (this.UI.basicWindow.length) {
        this.initEvents();
        this.initPublicAPI();
        this.checkConfig();

        setTimeout(() => {
          this.checkMap()
        }, 150);
      }
    }

    checkMap() {
        if (this.config.userConsents.maps === true) {
            if (document.querySelectorAll('.map').length > 0) {
                document.querySelectorAll('.map').forEach(map => {
                    map.classList.remove('is-hidden');
                })
            }
        }
    }

    checkConfig () {
      let config = Cookies.get('ss-cn-config-v2');

      // When no config - show popup
      if (!config) {
        if (this.config.debugMode) {
          console.log('⛔️ Consent config not found - showing popup');
        }

        this.showPopup();
        return;
      }

      // otherwise - load config without showing popup
      this.loadConfig(config);
    }

    loadConfig (configFromCookie) {
      this.config.userConsents.google = configFromCookie.indexOf('google') > -1;
      this.config.userConsents.hotjar = configFromCookie.indexOf('hotjar') > -1;
      this.config.userConsents.fb = configFromCookie.indexOf('fb') > -1;
      this.config.userConsents.maps = configFromCookie.indexOf('maps') > -1;
      this.config.userConsents.players = configFromCookie.indexOf('players') > -1;
      this.config.userConsents.gads = configFromCookie.indexOf('gads') > -1;
      this.config.userConsents.tiktok = configFromCookie.indexOf('tiktok') > -1;
      this.config.userConsents.linkedin = configFromCookie.indexOf('linkedin') > -1;
      this.config.userConsents.smarts = configFromCookie.indexOf('smarts') > -1;

      for (let i = 0; i < this.config.groups.length; i++) {
        let groupName = this.config.groups[i];

        if (this.config.userConsents[groupName]) {
            this.config.allowedGroups.push(groupName);
        }
      }

      if (this.config.debugMode) {
        console.log('⚙️ Founded config:', JSON.parse(JSON.stringify(this.config.userConsents)));
        this.checkMap()
      }
    }

    initEvents () {
      $(document).find('a[href="#cookie-settings"]').on('click', e => {
        e.preventDefault();
        this.showExtendedPopup();
      });

      this.UI.buttons.basicShowExtended.on('click', e => {
        e.preventDefault();
        this.closePopup();
        this.showExtendedPopup();
        this.checkMap();
      });

      this.UI.buttons.basicAcceptAll.on('click', e => {
        e.preventDefault();
        this.giveConsentForAll();
        this.closePopup();
        this.checkMap();
      });

      this.UI.buttons.basicRejectAll.on('click', e => {
        e.preventDefault();
        this.resetCookieConfig();
        this.closePopup();
        this.checkMap();
      });

      this.UI.buttons.extendedAcceptAll.on('click', e => {
        e.preventDefault();
        this.giveConsentForAll();
        this.closeExtendedPopup();
        this.checkMap();
      });

      this.UI.buttons.extendedSaveConfig.on('click', e => {
        e.preventDefault();
        this.saveConsent();
        this.closeExtendedPopup();
        this.checkMap();
      });

      this.UI.buttons.extendedNotNow.on('click', e => {
        e.preventDefault();
        this.closeExtendedPopup();
      });
    }

    setCheckboxes () {
      for (let i = 0; i < this.config.groups.length; i++) {
        let group = this.config.groups[i];

        if (this.config.userConsents[group]) {
          this.UI.checkboxes[group].prop('checked', true);
        } else {
          this.UI.checkboxes[group].prop('checked', false);
        }
      }
    }

    initPublicAPI () {
      window.ssCookieBanner = {
        giveConsentAndSave: (type) => this.giveConsentAndSave(type),
        giveConsentAndSaveAndLoad: (type) => this.giveConsentAndSave(type, true),
        showExtendedPopup: () => this.showExtendedPopup,
        getConsentStatus: (type) => this.getConsentStatus(type)
      };
    }

    showPopup () {
      this.UI.basicWindow.removeClass('is-hidden');
    }

    closePopup () {
      this.UI.basicWindow.addClass('is-hidden');
    }

    showExtendedPopup () {
      this.UI.extendedWindow.removeClass('is-hidden');
      $(document.body).addClass('no-scroll');
      this.setCheckboxes();
    }

    closeExtendedPopup () {
      this.UI.extendedWindow.addClass('is-hidden');
      $(document.body).removeClass('no-scroll');
    }

    giveConsentForAll () {
      this.giveConsent('google');
      this.giveConsent('fb');
      this.giveConsent('gads');
      this.giveConsent('smarts');
      this.saveConsentConfigInCookie();
    }

    giveConsent (type, forceOnLoad = false) { 
      if (this.config.debugMode) {
        console.log('✅ User gave consent:', type);
      }

      // if consent has been previously given - do nothing
      if (this.config.allowedGroups.indexOf(type) > -1) {
        return;
      }

      this.config.userConsents[type] = true;

      if (type === 'google') {
        this.allowGoogle();
        return;
      }

      if (type === 'gads') {
        this.allowGads();
        return;
      }

      if (type === 'hotjar') {
        this.allowHotJar();
        return;
      }

      if (type === 'fb') {
        this.allowFB();
        return;
      }

      if (type === 'maps') {
        this.allowMaps();
        return;
      }

      if (type === 'players') {
        this.allowPlayers();
        return;
      }

      if (type === 'tiktok') {
        this.allowTiktok();
        return;
      }

      if (type === 'smarts') {
        this.allowSmarts();
        return;
      }
    }

    giveConsentAndSave (type, forceOnLoad = false) {
      this.giveConsent(type, forceOnLoad);
      this.saveConsentConfigInCookie();
    }

    saveConsent () {
      let consentCompare = this.compareConsents();
      this.setConsentBasedOnCheckboxes();

      // if some consent has been removed
      if (consentCompare === 'some-rejected') {
        this.saveConsentConfigInCookie();
        window.location.reload();
        return;
      }

      // otherwise - if some consent has been added
      this.saveConsentConfigInCookie();
    }

    setConsentBasedOnCheckboxes () {
      for (let i = 0; i < this.config.groups.length; i++) {
        let group = this.config.groups[i];

        if (!!this.UI.checkboxes[group].prop('checked')) {
          this.giveConsent(group);
        }

        this.config.userConsents[group] = !!this.UI.checkboxes[group].prop('checked');
      }
    }

    saveConsentConfigInCookie () {
      let configToSave = [];

      for (let i = 0; i < this.config.groups.length; i++) {
        let group = this.config.groups[i];

        if (this.config.userConsents[group]) {
          configToSave.push(group);
        }
      }

      if (configToSave.length) {
        Cookies.set('ss-cn-config-v2', configToSave.join(';'), { expires: 180 });

        if (this.config.debugMode) {
          console.log('🍪 Consent config saved in the cookie');
        }
      } else {
        this.resetCookieConfig();
      }
    }

    resetCookieConfig () {
      Cookies.set('ss-cn-config-v2', 'null', { expires: 180 });

      if (this.config.debugMode) {
        console.log('🗑 User rejected all cookies - save empty config cookie');
      }
    }

    compareConsents () {
      let someRejected = false;

      for (let i = 0; i < this.config.groups.length; i++) {
        let group = this.config.groups[i];

        if (this.config.userConsents[group] && !this.UI.checkboxes[group].prop('checked')) {
          someRejected = true;
        }
      }

      if (someRejected) {
        if (this.config.debugMode) {
          console.log('🔄 Some consents has been rejected - website reload needed');
        }

        return 'some-rejected';
      }

      if (this.config.debugMode) {
        console.log('ℹ️ User not rejected any consents - website reload not needed');
      }

      return 'some-added-or-not-changed';
    }

    getConsentStatus (type) {
      return !!this.config.userConsents[type];
    }

    allowGoogle () {
      if (window.ssUnlocked.indexOf('google') > -1) {
        return;
      }

      window.ssUnlockConsentedScript('google', 'head', true);
    }

    allowGads () {
        if (window.ssUnlocked.indexOf('gads') > -1) {
          return;
        }

        window.ssUnlockConsentedScript('gads', 'head', true);
    }

    allowHotJar () {
        if (window.ssUnlocked.indexOf('hotjar') > -1) {
          return;
        }

        window.ssUnlockConsentedScript('hotjar', 'head', true);
    }

    allowFB () {
        if (window.ssUnlocked.indexOf('fb') > -1) {
          return;
        }

        window.ssUnlockConsentedScript('fb', 'head', true);
    }

    allowMaps() {
        if (window.ssUnlocked.indexOf('maps') > -1) {
            return;
        }

        window.ssUnlockMaps(true);
        window.ssUnlockConsentedScript('maps', 'body', true);
    }

    allowPlayers() {
        if (window.ssUnlocked.indexOf('players') > -1) {
            return;
        }

        window.ssUnlockPlayers(true);
    }

    allowTiktok () {
        if (window.ssUnlocked.indexOf('tiktok') > -1) {
          return;
        }

        window.ssUnlockConsentedScript('tiktok', 'head', true);
    }

    allowSmarts () {
        if (window.ssUnlocked.indexOf('smarts') > -1) {
            return;
        }

        window.ssUnlockConsentedScript('smarts', 'body', true);
    }
}
